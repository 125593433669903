<template>
  <section>
    <div class="title">
      <i
        class="ali-icon ali-icon-action-back"
        @click="backHistory"
      ></i>
      <span style="margin-left:10px">设备管理</span>
    </div>
    <section class="search-container" style="border: 1px solid #3f72ba;padding: 10px;">
      <a-form layout="inline" class="m-t-14">
        <a-form-item label="设备名称">
          <a-input class="search-input" placeholder="请输入" v-model="searchInfo.deviceName" />
        </a-form-item>
        <a-form-item label="设备类型">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.typeId"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="item in deviceTypeListData"
              :key="item.id"
              :value="item.id"
            >{{ item.typeName }}</a-select-option>
          </a-select>
        </a-form-item>
        <!-- 设备状态 -->
        <a-form-item label="设备状态">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.deviceState"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option value="0">离线</a-select-option>
            <a-select-option value="1">在线</a-select-option>
            <a-select-option value="2">故障</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="排序">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.monthAlarmsAsc"
          >
            <a-select-option value="false">预警数由高至低</a-select-option>
            <a-select-option value="true">预警数由低至高</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            class="m-r-8"
            @click="
              listMap.pageNum = 1;
              queryDeviceList();
            "
          >
            <a-icon type="queryDeviceList" />查询
          </a-button>
          <a-button
            @click="
              searchInfo = {};
              listMap.pageNum = 1;
              queryDeviceList();
            "
          >
            <a-icon type="reload" />重置
          </a-button>
        </a-form-item>
      </a-form>
    </section>
    <a-row v-if="listMap.data.length" type="flex" :gutter="20" class="list-card-container">
      <a-col :span="6" v-for="(item, key) in listMap.data" :key="key" class="m-t-16">
        <a-row class>
          <!-- <a-row class="list-card-bg"> -->
          <a-row type="flex" class="list-wrap">
            <a-col style="width: 100px" class="list-image">
              <img
                style="width: 100px; height: 100px"
                :src="disturbScopeStatus(item.typeId).img"
                alt
              />
            </a-col>
            <a-col style="width: calc(100% - 100px)">
              <a-row class="list-item" style="font-size: 16px; font-weight: 700; width: 78%">
                <span>{{ item.deviceName }}</span>
                <!-- <span style="margin-left:4px" v-if="item.jobName">({{item.jobName}})</span> -->
              </a-row>
              <a-row class="list-item">
                设备类型：
                <span style="color: #fff">
                  {{
                  disturbScopeStatus(item.typeId).typeName
                  }}
                </span>
              </a-row>
              <a-row class="list-item">
                30天预警：
                <span style="color: #fff">{{ item.monthAlarms }}次</span>
              </a-row>
              <a-row class="list-item">
                所在位置：
                <span style="color: #fff">
                  {{
                  item.location
                  }}
                </span>
              </a-row>

              <a-row class="list-btn-group">
                <a-button
                  size="small"
                  @click="
                    currentDevice = item;
                    detailsVisible = true;
                  "
                >查看</a-button>
                <a-dropdown :trigger="['click']">
                  <a-button size="small">
                    更多
                    <a-icon type="caret-down" />
                  </a-button>
                  <a-menu slot="overlay">
                    <a-menu-item key="1">
                      <a @click="editDevice(item)">编辑设备</a>
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a @click="showServiveForm(item)">维保设备</a>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-row>
            </a-col>
          </a-row>
          <a-tag
            class="list-status"
            :style="{
              color: item.deviceState == 1 ? '#4ED3F9' : 'rgba(255,131,60,1)',
              backgroundColor:
                item.deviceState == 1
                  ? 'rgba(78,211,249,0.2)'
                  : 'rgba(255,131,60,0.2)',
            }"
          >{{ item.deviceState == 1 ? "在线" : "离线" }}</a-tag>
        </a-row>
      </a-col>
    </a-row>
    <empty v-else :height="330" />
    <section class="pagination-container">
      <a-pagination
        :pageSize="listMap.pageSize"
        size="default"
        :current="listMap.pageNum"
        :total="listMap.total"
        :hideOnSinglePage="true"
        @change="changePageNum"
      />
    </section>

    <!-- modal 设备详情 -->
    <a-drawer
      class="drawer_module"
      :width="360"
      title="设备详情"
      placement="right"
      :visible="detailsVisible"
      @close="detailsVisible = false"
      :maskClosable="true"
      :destroyOnClose="true"
    >
      <a-row class="info-container">
        <section class="info-item-wrap">
          <a-row class="info-item" type="flex">
            <a-col class="key">设备编号：</a-col>
            <a-col class="val">{{ currentDevice.deviceNo }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">设备名称：</a-col>
            <a-col class="val">{{ currentDevice.deviceName }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">设备类型：</a-col>
            <a-col class="val">{{ currentDevice.typeName }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">设备厂商：</a-col>
            <a-col class="val">{{ currentDevice.manufactureName }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">设备型号：</a-col>
            <a-col class="val">{{ currentDevice.deviceModelName }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">IP地址：</a-col>
            <a-col class="val">{{ currentDevice.ip }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">所在位置：</a-col>
            <a-col class="val">{{ currentDevice.location }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">当前状态：</a-col>
            <a-col class="val">
              {{
              currentDevice.deviceState == 1 ? "在线" : "离线"
              }}
            </a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">30天预警：</a-col>
            <a-col class="val">{{ currentDevice.monthAlarms || 0 }} 次</a-col>
          </a-row>

          <a-row class="form-btn-group">
            <a-button class="close_button" @click="detailsVisible = false">关闭</a-button>
          </a-row>
        </section>
      </a-row>
    </a-drawer>

    <!-- edit modal -->
    <a-drawer
      class="drawer_module"
      :width="360"
      title="编辑设备"
      placement="right"
      :visible="editVisible"
      @close="editVisible = false"
      :maskClosable="true"
      :destroyOnClose="true"
    >
      <a-row class="info-container">
        <section class="info-item-wrap">
          <a-row class="info-item" type="flex">
            <a-col class="key">设备编号：</a-col>
            <a-col class="val">{{ currentDevice.deviceNo }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">设备类型：</a-col>
            <a-col class="val">{{ currentDevice.typeName }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">设备厂商：</a-col>
            <a-col class="val">{{ currentDevice.manufactureName }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">设备型号：</a-col>
            <a-col class="val">{{ currentDevice.deviceModelName }}</a-col>
          </a-row>
          <a-row class="info-item" type="flex">
            <a-col class="key">IP地址：</a-col>
            <a-col class="val">{{ currentDevice.ip }}</a-col>
          </a-row>
          <!-- <a-row class="info-item" type="flex">
                  <a-col class="key">所在位置：</a-col>
                  <a-col class="val">{{currentDevice.location}}</a-col>
          </a-row>-->
          <a-form layout="inline" :form="form">
            <a-row style="margin-bottom: 16px">
              <a-form-item style="margin-bottom: 0">
                <span style="color: #629ff0" slot="label">设备名称</span>
                <a-input
                  v-decorator="[
                    'deviceName',
                    {
                      rules: [
                        { required: false, message: '请输入设备名称' },
                        { max: 30, message: '最大输入30个字符' },
                      ],
                    },
                  ]"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item>
                <span style="color: #629ff0" slot="label">所在位置</span>
                <a-input
                  v-decorator="[
                    'location',
                    {
                      rules: [
                        { required: false, message: '请输入所在位置' },
                        { max: 30, message: '最大输入30个字符' },
                      ],
                    },
                  ]"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-row>
            <a-row class="form-btn-group">
              <a-button class="close_button" @click="editVisible = false">关闭</a-button>
              <a-button class="sub_button" @click="editSave">保存</a-button>
            </a-row>
          </a-form>
        </section>
      </a-row>
    </a-drawer>
    <!-- 维保 -->
    <a-drawer
      class="drawer_module"
      :width="400"
      title="维保设备"
      placement="right"
      :visible="serviveVisible"
      @close="
        serviveVisible = false;
        loadDataArr = [];
      "
      :maskClosable="true"
      :destroyOnClose="true"
    >
      <a-row class="info-container">
        <section class="info-item-wrap">
          <a-form :form="form">
            <a-row>
              <a-form-item
                style="margin-bottom: 16px"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
              >
                <span style="color: #629ff0" slot="label">设备名称</span>
                <a-input
                  disabled
                  v-decorator="[
                    'deviceName',
                    { rules: [{ required: true, message: '请输入设备名称' }] },
                  ]"
                  placeholder="请输入"
                />
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                style="margin-bottom: 16px"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
              >
                <span style="color: #629ff0" slot="label">维保负责人</span>
                <a-input-search
                  v-decorator="[
                    'maintainerName',
                    {
                      rules: [{ required: true, message: '请选择维保负责人' }],
                    },
                  ]"
                  readonly
                  enterButton="选择"
                  @search="showSelectPersonModal('maintainer')"
                />
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                style="margin-bottom: 16px"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
              >
                <span style="color: #629ff0" slot="label">联系方式</span>
                <a-input
                  disabled
                  v-decorator="[
                    'maintainerPhone',
                    { rules: [{ required: false }] },
                  ]"
                  placeholder="维保负责人联系方式"
                />
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                style="margin-bottom: 16px"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
              >
                <span style="color: #629ff0" slot="label">验收负责人</span>
                <a-input-search
                  v-decorator="[
                    'acceptorName',
                    {
                      rules: [{ required: false, message: '请选择验收负责人' }],
                    },
                  ]"
                  readonly
                  enterButton="选择"
                  @search="showSelectPersonModal('acceptor')"
                />
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                style="margin-bottom: 16px"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
              >
                <span style="color: #629ff0" slot="label">联系方式</span>
                <a-input
                  disabled
                  v-decorator="[
                    'acceptorPhone',
                    { rules: [{ required: false }] },
                  ]"
                  placeholder="验收负责人联系方式"
                />
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                style="margin-bottom: 16px"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
              >
                <span style="color: #629ff0" slot="label">问题照片</span>
                <a-row type="flex">
                  <upload
                    style="display: inline-block; width: 100px"
                    v-decorator="[
                      'faultPhotoUrl',
                      { rules: [{ required: false, message: '请上传照片' }] },
                    ]"
                    :node="{ type: 2, text: '上传' }"
                    :accept="['.png', '.jpg']"
                    :uploadUp="'uploadUpMore'"
                    @func="uploadFile"
                  />
                </a-row>
              </a-form-item>
              <a-row class="upload-preview-wrap m-b-10" v-if="loadDataArr.length">
                <a
                  v-for="(item, key) in loadDataArr"
                  :key="key"
                  class="file-item m-r-10"
                  style="height: 66px; width: 66px"
                >
                  <preview-image :data="item" :size="60" />
                  <a class="close-icon" title="删除" @click="deleteImage(item)">
                    <a-icon type="close"></a-icon>
                  </a>
                </a>
              </a-row>
            </a-row>
            <a-row>
              <a-form-item
                style="margin-bottom: 16px"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 16 }"
              >
                <span style="color: #629ff0" slot="label">问题说明</span>
                <a-textarea
                  style="height: 80px"
                  v-decorator="[
                    'faultDescription',
                    {
                      rules: [
                        { required: false, message: '请输入问题说明' },
                        { max: 200, message: '最大输入200个字符' },
                      ],
                    },
                  ]"
                  placeholder="请输入"
                ></a-textarea>
              </a-form-item>
            </a-row>
            <a-row class="form-btn-group">
              <a-button
                @click="
                  serviveVisible = false;
                  loadDataArr = [];
                "
                class="close_button"
              >关闭</a-button>
              <a-button class="sub_button" @click="serviveSave">保存</a-button>
            </a-row>
          </a-form>
        </section>
      </a-row>
    </a-drawer>

    <!-- 选择人员模态框 -->
    <a-modal
      width="1080px"
      class="modal-container"
      v-model="selectPersonVisible"
      title="人员列表"
      :footer="false"
    >
      <singlePerson @ok="getSelectPerson" @cancel="selectPersonVisible = false" />
    </a-modal>
  </section>
</template>

<script>
import empty from "@/components/empty";
import upload from "@/components/upload";
import singlePerson from "@/components/singlePerson";
import previewImage from "@/components/previewImage";
export default {
  data() {
    return {
      searchInfo: {},
      deviceTypeListData: [], //设备类型下拉列表

      //设备列表
      listMap: {
        pageNum: 1,
        pageSize: 12,
        total: 0,
        data: [],
      },
      currentDevice: {},
      detailsVisible: false,
      editVisible: false,
      serviveVisible: false,
      selectPersonVisible: false,
      currentSelectPersonItem: {
        type: "",
        acceptor: {},
        maintainer: {},
      },
      loadDataArr: [],
    };
  },
  components: { empty, singlePerson, upload, previewImage },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted(){
    if(this.$route.query.deviceState){
      this.searchInfo.deviceState = this.$route.query.deviceState
    }
  },
  created() {
    this.init();
    this.queryDeviceList();
  },
  computed: {
    userNickname() {
      return this.$store.state.userInfo.userNickname;
    },
    userPhone() {
      return this.$store.state.userInfo.phone;
    },
    userId() {
      return this.$store.state.userInfo.userId;
    },
    isSet() {
      return this.$route.query.deviceState?this.$route.query.deviceState:null;
    },
  },
  methods: {
    init() {
      this.currentSelectPersonItem.acceptor.name = this.userNickname;
      this.currentSelectPersonItem.acceptor.phone = this.userPhone;
      this.currentSelectPersonItem.acceptor.id = this.userId;
      this.deviceTypeList();
    },
    backHistory() {
       window.history.back()
    },
    // 设备类型
    deviceTypeList() {
      this.$api.deviceTypeList().then((res) => {
        if (res.code == "200") {
          this.deviceTypeListData = res.data || [];
        }
      });
    },
    queryDeviceList() {
      let _params = { ...this.searchInfo };
      _params.current = this.listMap.pageNum;
      _params.size = this.listMap.pageSize;
      if(this.isSet!=null && this.searchInfo.deviceState==null){
        _params.deviceState = this.isSet;
      }
      this.$api.getDeviceList(_params).then((res) => {
        if (res.code == "200") {
          this.listMap.data = (res.data && res.data.records) || [];
          this.listMap.total = (res.data && res.data.total) || 0;
        }
      });
    },
    disturbScopeStatus(Status) {
      let params = {
        typeName: "",
        img: "",
      };
      switch (Status) {
        case "1":
          params.typeName = "人脸识别设备";
          params.img = require("../../../assets/image/header/personType.png");
          break;
        case "2":
          params.typeName = "车辆识别设备";
          params.img = require("../../../assets/image/header/carType.png");
          break;
        case "3":
          params.typeName = "环境监测设备";
          params.img = require("../../../assets/image/header/envType.png");
          break;
        case "4":
          params.typeName = "塔吊监测设备";
          params.img = require("../../../assets/image/header/towerType.png");
          break;
        case "5":
          params.typeName = "工地广播设备";
          params.img = require("../../../assets/image/header/larmType.png");
          break;
        case "6":
          params.typeName = "视频监控设备";
          params.img = require("../../../assets/image/header/monitor.png");
          break;
        case "7":
          params.typeName = "LED屏";
          params.img = require("../../../assets/image/header/ledimg.png");
          break;
        case "8":
          params.typeName = "用电监测";
          params.img = require("../../../assets/image/header/ledimg.png");
          break;
      }

      return params;
    },
    //重置
    reset() {
      this.listMap.pageNum = 1;
      this.searchInfo = {};
      this.queryDeviceList();
    },
    //分页
    changePageNum(c) {
      this.listMap.pageNum = c;
      this.queryDeviceList();
    },

    //编辑设备
    editDevice(list) {
      this.currentDevice = list;
      this.editVisible = true;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          location: this.currentDevice.location,
          deviceName: this.currentDevice.deviceName,
        });
      });
    },
    cancelSelect() { },
    editSave() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          formData.id = this.currentDevice.id;
          this.$api.editDeviceDetail(formData).then((res) => {
            if (res.code == "200") {
              this.editVisible = false;
              this.queryDeviceList();
            }
          });
        }
      });
    },

    //维保
    showServiveForm(list) {
      this.currentDevice = list;
      this.serviveVisible = true;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          deviceName: this.currentDevice.deviceName,
          acceptorName: this.currentSelectPersonItem.acceptor.name,
          acceptorPhone: this.currentSelectPersonItem.acceptor.phone,
        });
      });
    },
    uploadFile(file) {
      this.loadDataArr = file.map((item) => item.url);
      this.$nextTick(() => {
        this.form.setFieldsValue({
          faultPhotoUrl: this.loadDataArr.join(","),
        });
      });
    },
    deleteImage(url) {
      this.loadDataArr = this.loadDataArr.filter((item) => item != url);
      this.$nextTick(() => {
        this.form.setFieldsValue({
          faultPhotoUrl: this.loadDataArr.join(","),
        });
      });
    },
    showSelectPersonModal(type) {
      this.currentSelectPersonItem.type = type;
      this.selectPersonVisible = true;
    },
    //获取维保、验收人员
    getSelectPerson(list) {
      let formData = {};
      let type = this.currentSelectPersonItem.type;
      this.selectPersonVisible = false;
      this.currentSelectPersonItem[type] = list;
      formData[type + "Name"] = list.name;
      formData[type + "Phone"] = list.phone;
      this.$nextTick(() => {
        this.form.setFieldsValue(formData);
      });
    },
    //添加维保
    serviveSave() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          formData.deviceId = this.currentDevice.id;
          formData.deviceName = this.currentDevice.deviceName;
          formData.acceptorId = this.currentSelectPersonItem.acceptor.id; //验收人员id
          formData.maintainerId = this.currentSelectPersonItem.maintainer.id; //维保人员id
          formData.customerName = this.userNickname;
          formData.customerPhone = this.userPhone;
          console.log(formData);
          this.$api.addServiceData(formData).then((res) => {
            if (res.code == "200") {
              this.serviveVisible = false;
              this.queryDeviceList();
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 2px;
  padding: 0 12px;
  margin-bottom: 5px;
}
</style>